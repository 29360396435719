import * as React from "react";
import { css } from "@emotion/react";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { BUTTON_SIZE } from "main/javascripts/constants/PagerConstants";

export interface IProps {
  children: React.ReactNode;
  styleKey?: keyof IStyle;
}

export const DisabledElement: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { children, styleKey = "default" } = props;
  return <li css={liStyle[styleKey]}>{children}</li>;
};

export interface IStyle {
  default: any;
  active: any;
  ellipsis: any;
}

const liBaseStyle = css`
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  padding: ${space.atom};
  width: ${BUTTON_SIZE};
  height: ${BUTTON_SIZE};
  line-height: ${BUTTON_SIZE};
`;

const liStyle: IStyle = {
  default: css`
    ${liBaseStyle};
  `,
  active: css`
    ${liBaseStyle};
    background-color: ${accentColor.primaryColor};
    color: #fff;
    cursor: default;
  `,
  ellipsis: css`
    ${liBaseStyle};
    cursor: default;
    color: #aaa;
    width: 1rem;
    padding-right: 0;
    padding-left: 0;
  `,
};
