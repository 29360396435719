import * as React from "react";
import { css } from "@emotion/react";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { accentColor, hex2rgba } from "main/javascripts/styles/base/colorStyle";
import { BUTTON_SIZE } from "main/javascripts/constants/PagerConstants";

export interface IProps {
  children: React.ReactNode;
  pageNum: number;
  handler(pageNum: number): void;
}

export const ClickableElement: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const onClick: () => void = () => {
    props.handler(props.pageNum);
  };
  return (
    <li onClick={onClick} css={[liStyle, liClickableStyle]} role="button">
      <a css={aClickableStyle}>{props.children}</a>
    </li>
  );
};

const liStyle = css`
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  padding: ${space.atom};
  width: ${BUTTON_SIZE};
  height: ${BUTTON_SIZE};
  line-height: ${BUTTON_SIZE};
`;
const liClickableStyle = css`
  transition: background 0.4s ease;
  &:hover {
    background-color: ${hex2rgba(accentColor.primaryColor, 0.1)};
  }
  &:active {
    background-color: ${hex2rgba(accentColor.primaryColor, 0.1)};
  }
`;
const aClickableStyle = css`
  color: ${accentColor.primaryColor};
`;
