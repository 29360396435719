import * as React from "react";
import { css } from "@emotion/react";
import { ClickableElement } from "main/javascripts/components/pager/ClickableElement";
import { DisabledElement } from "main/javascripts/components/pager/DisabledElement";
import { Icon } from "main/javascripts/components/icon/Icon";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { MAX_DISP_NUM } from "main/javascripts/constants/PagerConstants";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";

export interface IProps {
  totalNum: number; // Your data's length.
  handler(page: number): void; // Gets called when page is changed. You must implement your own. Otherwise crashes.
  numPerPage: number; // Max page number to display.
  maxPagerDispNum?: number; // (Optional) Max number of 'pager' to display. (default is 3)
  page: number; // Your current page should be set in your state.
}

export const Pager: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { totalNum, numPerPage } = props;

  const getPager: () => any = () => {
    const totalPageCount: number = Math.ceil(totalNum / numPerPage);
    let page: number | null;
    if (props.page < 1) {
      page = 1;
    } else if (props.page > totalPageCount) {
      page = totalPageCount;
    } else {
      page = props.page;
    }
    const maxPagerDispNum: number = props.maxPagerDispNum
      ? props.maxPagerDispNum
      : MAX_DISP_NUM;
    const range: number = Math.round((maxPagerDispNum - 1) / 2);

    const pageLinks: JSX.Element[] = [];
    pageLinks.push(getBackLiElement(page));

    if (page - range > 1) {
      pageLinks.push(getLiElement(1));
      if (page - range - 1 !== 1) {
        pageLinks.push(
          <DisabledElement key={2} styleKey="ellipsis">
            ...
          </DisabledElement>
        );
      }
    }
    const firstPage: number = Math.max(page - range, 1);
    const lastPage: number = Math.min(page + range, totalPageCount);
    for (let pageNum: number = firstPage; pageNum <= lastPage; pageNum += 1) {
      if (pageNum === page) {
        pageLinks.push(
          <DisabledElement key={pageNum} styleKey="active">
            {pageNum}
          </DisabledElement>
        );
      } else {
        pageLinks.push(getLiElement(pageNum));
      }
    }
    if (totalPageCount > page + range) {
      if (page + range + 1 !== totalPageCount) {
        pageLinks.push(
          <DisabledElement key={totalPageCount - 1} styleKey="ellipsis">
            ...
          </DisabledElement>
        );
      }
      pageLinks.push(getLiElement(totalPageCount));
    }
    pageLinks.push(getNextLiElement(totalPageCount, page));

    return pageLinks;
  };
  const getLiElement: (pageNum: number) => JSX.Element = (pageNum: number) => {
    return (
      <ClickableElement handler={props.handler} pageNum={pageNum} key={pageNum}>
        {pageNum}
      </ClickableElement>
    );
  };
  const getBackLiElement: (page: number) => JSX.Element = (page: number) => {
    let back: JSX.Element | null;
    if (page > 1) {
      const backPageNum: number = page - 1;
      back = (
        <ClickableElement key={0} handler={props.handler} pageNum={backPageNum}>
          <div css={arrowStyle}>
            <Icon styleKey="arrowLeft" />
          </div>
        </ClickableElement>
      );
    } else {
      back = (
        <DisabledElement key={0}>
          <div css={[arrowStyle, arrowDisabledStyle]}>
            <Icon styleKey="arrowLeft" />
          </div>
        </DisabledElement>
      );
    }
    return back;
  };
  const getNextLiElement: (
    totalPageCount: number,
    page: number
  ) => JSX.Element = (totalPageCount: number, page: number) => {
    let next: JSX.Element | null;
    if (page < totalPageCount) {
      const nextPageNum: number = page + 1;
      next = (
        <ClickableElement
          handler={props.handler}
          key={totalPageCount + 1}
          pageNum={nextPageNum}
        >
          <div css={arrowStyle}>
            <Icon styleKey="arrowRight" />
          </div>
        </ClickableElement>
      );
    } else {
      next = (
        <DisabledElement key={totalPageCount + 1}>
          <div css={[arrowStyle, arrowDisabledStyle]}>
            <Icon styleKey="arrowRight" size="" />
          </div>
        </DisabledElement>
      );
    }
    return next;
  };

  const pageLinks: JSX.Element = getPager();
  return (
    <nav role="navigation">
      <ul css={ulStyle}>{pageLinks}</ul>
    </nav>
  );
};

const ulStyle = css`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: ${fontSize.body};
  line-height: ${lineHeight.body1};
`;
const arrowStyle = css`
  font-size: 14px;
  padding: 3px 4px 5px;
  display: flex;
`;
const arrowDisabledStyle = css`
  opacity: 0.3;
  color: ${accentColor.primaryColor};
`;
